<template>
  <div class="column panel-error-elem">
    <div class="columns head">
      <div class="column">
        <h2>{{ $t('panels.error.headline') }}</h2>
        <p>{{ $t('panels.error.text') }}</p>
      </div>
    </div>
    <div class="columns" v-if="$store.getters.currentErrorMsg[0]">
      <div class="column table">
        <div class="columns table-head is-mobile">
          <div class="column"></div>
        </div>
        <div class="columns table-row is-mobile">
          <div class="column value is-emph">{{ $t('msg.' + $store.getters.currentErrorMsg[0], { param1: $t($store.getters.currentErrorMsg[1]), param2: $t($store.getters.currentErrorMsg[2])}) }}</div>
          <div class="column action">
            <button
              class="button is-primary is-small"
              role="button"
              @click="onClick($store.getters.currentErrorMsg[3])">
                {{ $t('panels.error.button') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fn from '../../services/GlobalService'
import InputModelCollection from '../../models/InputModelCollection'

/**
 * errno: 11, 12, 17, 20, 21, 22, 23
 *
 * Structure of errmsg
 * strno | param1 | param2 | object with adjust-values or false if none
 */
export default {
  name: 'panel-error-elem',
  methods: {
    onClick (adj) {
      var Collection
      Collection = new InputModelCollection()
      for (var key in adj) {
        Collection.new(key, adj[key])
      }
      this.$store.commit('setInputValues', Collection)
    }
  }
}
</script>

<style lang="sass">
</style>
