<template>
  <section class="section product-select-segment">
    <div class="container is-clearfix">
        <svg-icon name="tke_icon_products" class="is-medium is-primary"></svg-icon>
        <router-link :to="{ params: { product: 'evolution200'} }">
          {{ $t('products.evolution200') }}
        </router-link>
        <router-link :to="{ params: { product: 'evolution300'} }">
          {{ $t('products.evolution300') }}
        </router-link>
        <icon-button
          icon="tke_icon_settings"
          class="settings is-flip"
          @click.native="onSettingsClick">
            {{ $t('navigation.settings') }}
        </icon-button>
    </div>
  </section>
</template>

<script>
import fn from '../../services/GlobalService'
import bus from '../../services/EventService'

export default {
  name: 'product-select-segment',
  methods: {
    onSettingsClick () {
      bus.fire('calc/toggle-settings')
    }
  }
}
</script>

<style lang="sass">
.product-select-segment
  display: none
  z-index: $z-index-content

+tablet

  .product-select-segment
    display: block
    padding: .3rem 0 .3rem 0
    background-color: $white

    .container
      display: flex
      a
        padding: .25rem .4rem
        font-size: $size-4
        font-family: $family-sans-serif
        &.router-link-exact-active,
        &:hover
          color: $primary
          &::after
            content: ""
            display: block
            border-top: 1px solid $primary
            left: 0
            right: 0
            bottom: -.2rem
      .settings
        margin-left: auto
+desktop

  .product-select-segment
    .container
      a
        padding: .25rem .8rem

</style>
