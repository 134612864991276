<template>
  <div class="column panel-adjustments-elem">
    <div class="columns head">
      <div class="column">
        <h2>{{ $t('panels.adjustments.headline') }}</h2>
        <p>{{ text }}</p>
      </div>
    </div>
    <div class="columns">
      <div class="column table">
        <div class="columns table-head is-mobile">
          <div class="column">{{ col1 }}</div>
          <div class="column">{{ col2 }}</div>
          <div class="column"></div>
        </div>
        <div class="columns table-row is-mobile"
          v-for="(adj, n) in $store.getters.currentDoor.adjustments"
          :key="n">
            <div class="column value" :class="{ 'is-emph': adj[2] }">{{ adj[0] }} {{ unit1 }}</div>
            <div class="column value" :class="{ 'is-emph': adj[3] }">{{ adj[1] }} {{ unit2 }}</div>
            <div class="column action">
              <button
                class="button is-primary is-small"
                role="button"
                @click="onClick(adj)">
                  {{ $t('panels.adjustments.button') }}
              </button>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import fn from '../../services/GlobalService'
import InputModelCollection from '../../models/InputModelCollection'

/**
 * errno: 18, 19
 *
 * Structure of adjustments
 * 19: [[ kb, kt, kbChangeBool, ktChangeBool ], []]
 * 18: [[ sb, st, sbChangeBool, stChangeBool ], []]
 */
export default {
  name: 'panel-adjustments-elem',
  computed: {
    text () {
      if (this.$store.getters.isCabin) {
        return this.$t('panels.adjustments.cabin.text')
      }
      if (this.$store.getters.isShaft) {
        return this.$t('panels.adjustments.shaft.text')
      }
      return ''
    },
    col1 () {
      if (this.$store.getters.isCabin) {
        return this.$t('panels.adjustments.cabin.col1')
      }
      if (this.$store.getters.isShaft) {
        return this.$t('panels.adjustments.shaft.col1')
      }
      return ''
    },
    col2 () {
      if (this.$store.getters.isCabin) {
        return this.$t('panels.adjustments.cabin.col2')
      }
      if (this.$store.getters.isShaft) {
        return this.$t('panels.adjustments.shaft.col2')
      }
      return ''
    },
    unit1 () {
      if (this.$store.getters.isCabin) {
        return this.$t('result.kb.unit')
      }
      if (this.$store.getters.isShaft) {
        return this.$t('result.sb.unit')
      }
      return ''
    },
    unit2 () {
      if (this.$store.getters.isCabin) {
        return this.$t('result.kt.unit')
      }
      if (this.$store.getters.isShaft) {
        return this.$t('result.st.unit')
      }
      return ''
    }
  },
  methods: {
    onClick (adj) {
      var Collection
      Collection = new InputModelCollection()
      if (this.$store.getters.isCabin) {
        if (adj[2]) {
          Collection.new('kb', adj[0])
        }
        if (adj[3]) {
          Collection.new('kt', adj[1])
        }
      }
      if (this.$store.getters.isShaft) {
        if (adj[2]) {
          Collection.new('sb', adj[0])
        }
        if (adj[3]) {
          Collection.new('st', adj[1])
        }
      }
      this.$store.commit('setInputValues', Collection)
    }
  }
}
</script>

<style lang="sass">
</style>
