<template>
  <div class="mobile-menu inputs-segment" ref="inputsSegment" :class="{ 'is-visible': mobileMenuVisible }">
    <div class="container">
      <div class="toolbar">
        <icon-button
          icon="tke_icon_check"
          class="close is-white"
          @click.native="hide">
        </icon-button>
        <icon-button
          icon="tke_icon_settings"
          class="settings is-white"
          @click.native="onSettingsClick">
            {{ $t('navigation.settings') }}
        </icon-button>
      </div>
    </div>
    <vue-slide-up-down :active="settingsVisible" :duration="600" v-show="isRendered">
      <section class="section section-settings">
        <div class="container">
          <div class="columns" v-if="$store.getters.hasSeInput">
            <div class="column">
              <select-input
                name="se_cabin"
                v-if="$store.getters.isCabin"
                :options="$store.getters.get('se_cabin', 'options')"
                :preset="$store.getters.get('se_cabin', 'value')"
                :label="$store.getters.get('se_cabin', 'label')">
              </select-input>
              <select-input
                name="se_shaft"
                v-if="$store.getters.isShaft"
                :options="$store.getters.get('se_shaft', 'options')"
                :preset="$store.getters.get('se_shaft', 'value')"
                :label="$store.getters.get('se_shaft', 'label')">
              </select-input>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <radio-input
                name="fh"
                :options="$store.getters.get('fh', 'options')"
                :preset="$store.getters.get('fh', 'value')"
                :label="$store.getters.get('fh', 'label')"
                :error="$store.getters.getInputError('fh')">
              </radio-input>
            </div>
            <div class="column">
              <select-input
                name="ge"
                :options="$store.getters.get('ge', 'options')"
                :preset="$store.getters.get('ge', 'value')"
                :label="$store.getters.get('ge', 'label')"
                :error="$store.getters.getInputError('ge')">
              </select-input>
            </div>
            <div class="column">
              <radio-input
                name="dl"
                :options="$store.getters.get('dl', 'options')"
                :preset="$store.getters.get('dl', 'value')"
                :label="$store.getters.get('dl', 'label')"
                :error="$store.getters.getInputError('dl')">
              </radio-input>
            </div>
            <div class="column">
              <select-input
                name="tn"
                :options="$store.getters.get('tn', 'options')"
                :preset="$store.getters.get('tn', 'value')"
                :label="$store.getters.get('tn', 'label')"
                :error="$store.getters.getInputError('tn')">
              </select-input>
            </div>
          </div>
          <div class="columns">
            <div class="column is-one-quarter">
              <radio-input
                name="skRed"
                :options="$store.getters.get('skRed', 'options')"
                :preset="$store.getters.get('skRed', 'value')"
                :label="$store.getters.get('skRed', 'label')"
                :error="$store.getters.getInputError('skRed')">
              </radio-input>
            </div>
            <div class="column is-one-quarter">
              <radio-input
                name="sgRed"
                :options="$store.getters.get('sgRed', 'options')"
                :preset="$store.getters.get('sgRed', 'value')"
                :label="$store.getters.get('sgRed', 'label')"
                :error="$store.getters.getInputError('sgRed')">
              </radio-input>
            </div>
          </div>
        </div>
      </section>
    </vue-slide-up-down>
    <section class="section section-inputs">
      <div class="container">
        <div class="columns">
          <div class="column is-6">
            <slider-input
              v-if="$store.getters.isCabin"
              name="kb"
              tabindex="1"
              :preset="$store.getters.get('kb', 'value')"
              :label="$store.getters.get('kb', 'label')"
              :unit="$store.getters.get('kb', 'unit')"
              :min="$store.getters.get('kb', 'min')"
              :max="$store.getters.get('kb', 'max')"
              :step="$store.getters.get('kb', 'step')"
              :sliderStep="$store.getters.get('kb', 'sliderStep')"
              :rangeMin="$store.getters.get('kb', 'rangeMin')"
              :rangeMax="$store.getters.get('kb', 'rangeMax')"
              :lockable="$store.getters.get('kb', 'lockable')"
              :error="$store.getters.getInputError('kb')">
            </slider-input>
            <slider-input
              v-if="$store.getters.isShaft"
              name="sb"
              tabindex="1"
              :preset="$store.getters.get('sb', 'value')"
              :label="$store.getters.get('sb', 'label')"
              :unit="$store.getters.get('sb', 'unit')"
              :min="$store.getters.get('sb', 'min')"
              :max="$store.getters.get('sb', 'max')"
              :step="$store.getters.get('sb', 'step')"
              :sliderStep="$store.getters.get('sb', 'sliderStep')"
              :rangeMin="$store.getters.get('sb', 'rangeMin')"
              :rangeMax="$store.getters.get('sb', 'rangeMax')"
              :lockable="$store.getters.get('sb', 'lockable')"
              :error="$store.getters.getInputError('sb')">
            </slider-input>
          </div>
          <div class="column is-6">
            <slider-input
              v-if="$store.getters.isCabin"
              name="kt"
              tabindex="3"
              :preset="$store.getters.get('kt', 'value')"
              :label="$store.getters.get('kt', 'label')"
              :unit="$store.getters.get('kt', 'unit')"
              :min="$store.getters.get('kt', 'min')"
              :max="$store.getters.get('kt', 'max')"
              :step="$store.getters.get('kt', 'step')"
              :sliderStep="$store.getters.get('kt', 'sliderStep')"
              :rangeMin="$store.getters.get('kt', 'rangeMin')"
              :rangeMax="$store.getters.get('kt', 'rangeMax')"
              :lockable="$store.getters.get('kt', 'lockable')"
              :error="$store.getters.getInputError('kt')">
            </slider-input>
            <slider-input
              v-if="$store.getters.isShaft"
              name="st"
              tabindex="3"
              :preset="$store.getters.get('st', 'value')"
              :label="$store.getters.get('st', 'label')"
              :unit="$store.getters.get('st', 'unit')"
              :min="$store.getters.get('st', 'min')"
              :max="$store.getters.get('st', 'max')"
              :step="$store.getters.get('st', 'step')"
              :sliderStep="$store.getters.get('st', 'sliderStep')"
              :rangeMin="$store.getters.get('st', 'rangeMin')"
              :rangeMax="$store.getters.get('st', 'rangeMax')"
              :lockable="$store.getters.get('st', 'lockable')"
              :error="$store.getters.getInputError('st')">
            </slider-input>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <slider-input
              name="tb"
              tabindex="2"
              :preset="$store.getters.get('tb', 'value')"
              :label="$store.getters.get('tb', 'label')"
              :unit="$store.getters.get('tb', 'unit')"
              :min="$store.getters.get('tb', 'min')"
              :max="$store.getters.get('tb', 'max')"
              :step="$store.getters.get('tb', 'step')"
              :sliderStep="$store.getters.get('tb', 'sliderStep')"
              :rangeMin="$store.getters.get('tb', 'rangeMin')"
              :rangeMax="$store.getters.get('tb', 'rangeMax')"
              :lockable="$store.getters.get('tb', 'lockable')"
              :error="$store.getters.getInputError('tb')">
            </slider-input>
          </div>
          <div class="column is-6">
            <slider-input
              name="kh"
              tabindex="4"
              :preset="$store.getters.get('kh', 'value')"
              :label="$store.getters.get('kh', 'label')"
              :unit="$store.getters.get('kh', 'unit')"
              :min="$store.getters.get('kh', 'min')"
              :max="$store.getters.get('kh', 'max')"
              :step="$store.getters.get('kh', 'step')"
              :sliderStep="$store.getters.get('kh', 'sliderStep')"
              :rangeMin="$store.getters.get('kh', 'rangeMin')"
              :rangeMax="$store.getters.get('kh', 'rangeMax')"
              :lockable="$store.getters.get('kh', 'lockable')"
              :error="$store.getters.getInputError('kh')">
            </slider-input>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import fn from '../../services/GlobalService'
import Bus from '../../services/EventService'
import VueSlideUpDown from 'vue-slide-up-down'

export default {
  name: 'inputs-segment',
  components: {
    'vue-slide-up-down': VueSlideUpDown
  },
  data () {
    return {
      mobileMenuVisible: false,
      settingsVisible: false,
      isRendered: true,
      windowWidth: null
    }
  },
  computed: {},
  methods: {
    show () {
      this.mobileMenuVisible = true
      this.windowWidth = window.innerWidth
      window.addEventListener('resize', this.handleResize)
      this.$refs.inputsSegment.scrollTo(0, 0)
      Bus.fire('global/show-overlay-menu', 'global/hide-input-menu')
      Bus.fire('calc/on-mobile-input-opened')
    },
    hide () {
      this.mobileMenuVisible = false
      if (this.settingsVisible) { // prevent double height of element
        this.toggleSettings()
      }
      window.removeEventListener('resize', this.handleResize)
      window.scrollTo(0, 0)
      Bus.fire('global/hide-overlay')
    },
    handleResize () {
      if (window.innerWidth !== this.windowWidth && this.mobileMenuVisible) {
        this.hide()
      }
    },
    toggleSettings () {
      this.settingsVisible = !this.settingsVisible
    },
    onSettingsClick () {
      this.toggleSettings()
    }
  },
  created () {},
  mounted () {
    Bus.listen('global/show-input-menu', this.show)
    Bus.listen('global/hide-input-menu', this.hide)
    Bus.listen('calc/toggle-settings', this.toggleSettings)
    this.$nextTick(() => { // avoid flickering on IE
      this.isRendered = true
    })
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style lang="sass">
.inputs-segment
  z-index: $z-index-menu + 1

+tablet

  .inputs-segment
    z-index: $z-index-content

    .input-help
      font-size: $size-6
      line-height: 1rem

    .section-settings
      padding: .8rem 0 1.6rem 0
      background-color: $white
      border-top: 1px solid $border
      border-bottom: 1px solid $border
      overflow: hidden

    .section-inputs
      padding: 1.6rem 0 .8rem 0
      background-color: $white-ter

+desktop

  .inputs-segment

    .input-help
      font-size: $size-5
      line-height: 1.2rem

</style>
