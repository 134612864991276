<template>
  <div class="column panel-result-elem">

    <!-- desktop an table view -->
    <div class="tile is-ancestor is-desktop">
      <div class="tile is-parent is-3 has-border-bottom has-border-right">
        <div class="tile is-child door-image">
          <svg-icon :name="img"></svg-icon>
        </div>
      </div>
      <div class="tile is-parent is-vertical is-3 has-border-bottom">
        <result-value name="nl" class="tile is-child" icon="tke_icon_load"></result-value>
        <result-value name="pe" class="tile is-child" icon="tke_icon_people"></result-value>
      </div>
      <div class="tile is-parent is-vertical is-3 has-border-bottom has-border-right">
        <result-value v-if="$store.getters.isCabin" name="sb" class="tile is-child" icon="tke_icon_min-shaft-width"></result-value>
        <result-value v-if="$store.getters.isShaft" name="kb" class="tile is-child" icon="tke_icon_max-cabin-width"></result-value>
        <result-value v-if="$store.getters.isCabin" name="st" class="tile is-child" icon="tke_icon_min-shaft-depth"></result-value>
        <result-value v-if="$store.getters.isShaft" name="kt" class="tile is-child" icon="tke_icon_max-cabin-height"></result-value>
      </div>
      <div class="tile is-parent is-vertical is-3">
        <result-value name="sk" class="tile is-child" icon="tke_icon_shaft-head"></result-value>
      </div>
    </div>
    <div class="tile is-ancestor is-desktop">
      <div class="tile is-parent is-3">
        <div class="tile is-child wa-label">
          <p>{{ $t('result.wa') }}</p>
        </div>
      </div>
      <div class="tile is-parent is-6 has-border-right">
        <result-value name="wa1" class="tile is-child" icon="tke_icon_arrow-left"></result-value>
        <result-value name="wa2" class="tile is-child" icon="tke_icon_arrow-right"></result-value>
        <result-value name="wa3" class="tile is-child" icon="tke_icon_arrow-back"></result-value>
      </div>
      <div class="tile is-parent is-3">
        <result-value name="sg" class="tile is-child" icon="tke_icon_shaft-pit"></result-value>
      </div>
    </div>

    <!-- mobile view -->
    <div class="columns is-mobile">
      <div class="column door-image">
        <svg-icon :name="img"></svg-icon>
      </div>
    </div>
    <div class="columns is-mobile">
      <result-value name="nl" class="column" icon="tke_icon_load"></result-value>
      <result-value name="pe" class="column" icon="tke_icon_people"></result-value>
    </div>
    <div class="columns is-mobile">
      <result-value v-if="$store.getters.isCabin" name="sb" class="tile is-child" icon="tke_icon_min-shaft-width"></result-value>
      <result-value v-if="$store.getters.isShaft" name="kb" class="tile is-child" icon="tke_icon_max-cabin-width"></result-value>
      <result-value v-if="$store.getters.isCabin" name="st" class="tile is-child" icon="tke_icon_min-shaft-depth"></result-value>
      <result-value v-if="$store.getters.isShaft" name="kt" class="tile is-child" icon="tke_icon_max-cabin-height"></result-value>
    </div>
    <div class="columns is-mobile">
      <result-value name="sk" class="tile is-child" icon="tke_icon_shaft-head"></result-value>
    </div>
    <div class="columns is-mobile">
      <result-value name="sg" class="tile is-child" icon="tke_icon_shaft-pit"></result-value>
    </div>
    <div class="columns is-mobile">
      <div class="column wa-label">
        <p>{{ $t('result.wa') }}</p>
      </div>
    </div>
    <div class="columns is-mobile">
      <result-value name="wa1" class="tile is-child" icon="tke_icon_arrow-left"></result-value>
      <result-value name="wa2" class="tile is-child" icon="tke_icon_arrow-right"></result-value>
    </div>
    <div class="columns is-mobile">
      <result-value name="wa3" class="tile is-child" icon="tke_icon_arrow-back"></result-value>
    </div>
  </div>
</template>

<script>
import fn from '../../services/GlobalService'

export default {
  name: 'panel-result-elem',
  computed: {
    img () {
      return 'tke_' +
        this.$store.getters.currentDoor.settings.key +
        '-' + this.$store.getters.get('tn', 'value') +
        '-' + this.$store.getters.get('dl', 'value')
    }
  }
}
</script>

<style lang="sass">
.panel-result-elem
  padding: ($column-gap * 3) ($column-gap * 2)

  .is-desktop
    display: none

  .is-mobile
    display: flex
    flex-wrap: wrap

  .door-image
    display: flex
    justify-content: center

    .svg-icon
      width: 180px
      height: auto

  .wa-label

    p
      padding-top: .6rem
      font-family: $family-mono-light
      font-size: $size-4
      line-height: 1.2em

+tablet
  .panel-result-elem

    .is-desktop
      display: flex

    .is-mobile
      display: none

    .tile

      &.is-parent
        padding: .75rem .4rem

      &.is-vertical

        & > .tile.is-child:not(:last-child)
          margin-bottom: 1.5rem !important

    .door-image
      display: flex
      justify-content: center

      .svg-icon
        width: 140px

    .wa-label
      display: flex
      flex-direction: column
      justify-content: center
      text-align: right

      p
        font-size: $size-6

    .has-border-bottom
      border-bottom: 2px solid $border

    .has-border-right
      border-right: 2px solid $border

+desktop
  .panel-result-elem

    .tile

      &.is-parent
        padding: .75rem

    .door-image

      .svg-icon
        width: 180px

</style>
