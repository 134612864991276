<template>
  <section class="section result-segment">
    <div class="container">
      <div class="columns doors-wrapper">
        <div class="column">
          <div class="columns doors is-mobile">
            <div
              class="column door-tab"
              v-for="door in doors"
              :key="door"
              @click="setDoor(door)"
              :class="{ 'is-active': $store.getters.isDoor(door) }">
                <div class="name">{{ $t('doors.' + door + '.name') }}</div>
                <div class="description">{{ $t('doors.' + door + '.description') }}</div>
                <div class="error">
                  <svg-icon
                    name="tke_icon_warning"
                    class="danger"
                    width="1.4rem"
                    height="1.4rem"
                    v-if="$store.getters.hasError(door)">
                  </svg-icon>
                </div>
            </div>
          </div>
        </div>
      </div>
      <div class="columns panel-wrapper">
          <div class="column">
            <div class="columns panel">
              <keep-alive>
                <component :is="currentPanelComponent" :key="currentPanelComponent"></component>
              </keep-alive>
            </div>
          </div>
      </div>
      <div class="columns actions-wrapper is-mobile" v-if="$store.getters.currentErrorPanel <= 0">
        <div class="column left">
          <icon-button
            icon="tke_icon_tipp"
            class="is-medium"
            :class="{ 'is-inactive': $store.getters.currentErrorPanel > 0 }"
            :count="$store.getters.tippsCount"
            @click.native="onClickTipps">
              {{ $t('panels.tipps.text') }}
          </icon-button>
        </div>
        <div class="column right">
          <icon-button
            icon="tke_icon_info"
            class="is-medium"
            :class="{ 'is-inactive': $store.getters.currentErrorPanel > 0 }"
            @click.native="onClickInfo">
          </icon-button>
          <icon-button
            icon="tke_icon_print"
            class="is-medium"
            :class="{ 'is-inactive': $store.getters.currentErrorPanel > 0 }"
            @click.native="onClickPrint">
          </icon-button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import fn from '../../services/GlobalService'
import { doors } from '../../config/global.json'
import Bus from '../../services/EventService'
import PanelResultElem from '../elements/PanelResultElem'
import PanelErrorElem from '../elements/PanelErrorElem'
import PanelAdjustmentsElem from '../elements/PanelAdjustmentsElem'
import PanelMultiElem from '../elements/PanelMultiElem'
import PanelFatalElem from '../elements/PanelFatalElem'

export default {
  name: 'result-segment',
  components: {
    'panel-1-elem': PanelResultElem,
    'panel-5-elem': PanelErrorElem,
    'panel-6-elem': PanelAdjustmentsElem,
    'panel-7-elem': PanelMultiElem,
    'panel-8-elem': PanelFatalElem
  },
  data () {
    return {}
  },
  computed: {
    doors () {
      return doors
    },
    currentPanelComponent () {
      return 'panel-' + (this.$store.getters.currentErrorPanel || 1) + '-elem'
    }
  },
  methods: {
    setDoor (door) {
      this.$store.dispatch(door + '/selectDoor')
    },
    onClickTipps () {
      Bus.fire('calc/show-tipps')
    },
    onClickInfo () {},
    onClickPrint () {
      var height, width, left, top, route, Win
      if (this.$store.getters.currentErrorPanel > 0) {
        return
      }
      route = this.$router.resolve({ name: 'print' })
      height = 1000
      width = 690
      left = Math.floor((screen.width / 2) - (width / 2))
      top = Math.floor((screen.height / 2) - (height / 2))
      Win = window.open(route.href + '?' + this.$store.getters.printParams, 'Print', 'status=no,toolbar=no,location=no,menubar=no,directories=no,resizeable=no,scrollbars=1,height=' + height + ',width=' + width + ',left=' + left + ',top=' + top)
      Win.focus()
    }
  }
}
</script>

<style lang="sass">
.result-segment
  background-color: $white-ter
  z-index: $z-index-content

  .doors-wrapper

    &>.column
      padding-bottom: 0 // glue tabs together with panel

    .doors
      margin: 0

      .door-tab
        display: flex
        margin: 0 2px -1px 2px
        min-height: 2.8rem
        background-color: $grey-lighter
        border: 1px solid $border
        cursor: pointer
        z-index: $z-index-content + 1

        &:first-child
          margin-left: 0

        &:last-child
          margin-right: 0

        &.is-active
          background-color: $white
          border-bottom: none
          z-index: $z-index-content + 3

        .name
          flex-grow: 2
          margin-left: -.4rem
          padding-right: .2rem
          font-family: $family-mono
          color: $primary
          font-size: $size-3
          line-height: 1em

        .description
          display: none
          vertical-align: top
          font-size: $size-7
          font-family: $family-mono-light
          line-height: 1.25em

        .error
          text-align: right

          .svg-icon
            margin-right: -.4rem

  .panel-wrapper
    margin-bottom: 0

    &>.column
      padding-top: 0 // glue tabs together with panel

    .panel
      margin: 0
      border: 1px solid $border
      background-color: $white
      z-index: $z-index-content + 2

      .head
        margin-bottom: 0

        h2
          margin-bottom: .3rem
          width: 90%
          font-family: $family-sans-serif-medium

        p
          width: 90%
          font-size: $size-5

      .table
        padding: ($column-gap * 2)

        .table-head

          .column
            padding: .4rem 0
            font-size: $size-5
            border-bottom: 2px solid $border

        .table-row

          .column
            padding: .6rem 0
            font-size: $size-5
            border-bottom: 1px solid $border

            &.value
              flex-grow: 2

            &.action
              flex-grow: 1
              text-align: right

            &.is-emph
              font-family: $family-sans-serif-bold

        &:last-child

          .column
            border-bottom: 2px solid $border

  .actions-wrapper

    .right
      text-align: right

    .tke_icon_print,
    .tke_icon_info
      display: none

+tablet

  .result-segment
    padding: .8rem 0 1.6rem 0
    background-color: $white-ter

    .doors-wrapper

      .doors

        .door-tab
          min-height: 3.6rem

          .name
            flex-grow: 1
            padding-right: .4rem
            margin-left: 0
            font-size: $size-2

          .description
            flex-grow: 2
            display: block

          .error
            width: 2.4rem

+desktop

  .result-segment

    .actions-wrapper

      .tke_icon_print
        display: none // block

</style>
